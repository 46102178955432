.counter-section i { display:block; margin:0 0 10px}
.counter-section span.counter { font-size:40px; color:#000; line-height:60px; display:block; font-family: "Oswald",sans-serif; letter-spacing: 2px}
.counter-title{ font-size:12px; letter-spacing:2px; text-transform: uppercase; font-weight: 600;}
.counter-icon {top:25px; position:relative}
.counter-style2 .counter-title {letter-spacing: 0.55px; float: left;}
.counter-style2 span.counter {letter-spacing: 0.55px; float: left; margin-right: 10px;}
.counter-style2 i {float: right; line-height: 26px; margin: 0 10px 0 0}
.counter-subheadline span {float: right;}  

.medium-icon {
    font-size: 40px !important;
    margin-bottom: 15px !important;
} 

.wow {
    background-color: #3F4E4F;
}

.fa-tasks {
    color:#F7F7F7;
}

.fa-heart {
    color:#F7F7F7;

}

.fa-anchor {
    color:#F7F7F7;

}

.fa-user {
    color:#F7F7F7;

}