.ServicesPage {
    background-color: #DAE2B6;
}

.card{
    background-color: white;

}

h3 {
    display: inline-block;
    padding-bottom: 5px;
    position: relative;
  }
  h3:before{
      content: "";
      position: absolute;
      width: 50%;
      height: 1px;
      bottom: 0;
      left: 25%;
      border-bottom: 4px solid #A8E890;
  }

.servicedesc {
    font-size:17px !important;
    text-align: justify;
    
}

@media only screen and (max-width: 990px) {
   .webdevpart {
    margin-top: 0 !important;
   }

   .productpart {
    margin-top: 0 !important;
   }

   .mvppart {
    margin-top: 0 !important;
   }
  }